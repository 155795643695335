import {EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    List,
    Datagrid,
    DateField,
    DateInput,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    EmailField,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    SelectArrayInput,
    Show,
    TabbedShowLayout,
    Admin,
    Resource,
    useEditContext,
    useShowContext,
    useRecordContext,
    TabbedForm,
    EditButton,
    ReferenceField,
    SingleFieldList,
    FunctionField,
    ChipField,
    Button,
    SelectInput,
    TopToolbar,
    DatagridConfigurable,
    SelectColumnsButton, FilterButton, CreateButton, ExportButton, ReferenceInput, AutocompleteInput, ShowButton,
} from "react-admin";
import {Link} from "react-router-dom";

const UtilisateurDemandeListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const types = [
    { id: 'changement_email', name: 'Changement Email' },
    { id: 'changement_telephone', name: 'Changement téléphone' },
    { id: 'invitation', name: 'Invitation' },
    { id: 'reinvitation', name: 'Réinvitation' },
    { id: 'reinitialisation_motpasse', name: 'Réinit Mot de passe' },
];

const utilisateurDemandeFilters = [
    <ReferenceInput source="utilisateur_id" reference="utilisateurs" name={"Utilisateur"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
            name={"utilisateur"}/>
    </ReferenceInput>,
    <SelectInput source="type" name={"type"} field={"type"} choices={types} />,
    <TextInput source="data" label="Data" />,
];

export const UtilisateurDemandeList = props => (
    <List {...props} filters={utilisateurDemandeFilters} actions={<UtilisateurDemandeListActions />}>
        <DatagridConfigurable rowClick={"edit"}>
            <ReferenceField reference="utilisateurs" source="utilisateur" label="Utilisateur">
                <TextField source="name" />
            </ReferenceField>
            <FieldGuesser source={"code"} />
            <FieldGuesser source={"date"} />
            <FieldGuesser source={"dateValidite"} />
            <FieldGuesser source={"nombreTentative"} />
            <FieldGuesser source={"type"} />
            <FieldGuesser source={"data"} />
            <ShowButton />
            <EditButton />
        </DatagridConfigurable>
    </List>
);

export const UtilisateurDemandeShow = props => (
    <ShowGuesser {...props}>
            <ReferenceField reference="utilisateurs" source="utilisateur" label="Utilisateur">
                <TextField source="name" />
            </ReferenceField>
            <FieldGuesser source={"code"} />
            <FieldGuesser source={"date"} />
            <FieldGuesser source={"dateValidite"} />
            <FieldGuesser source={"nombreTentative"} />
            <FieldGuesser source={"type"} />
            <FieldGuesser source={"data"} />
    </ShowGuesser>
);

export const UtilisateurDemandeEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"utilisateur"} />
        <InputGuesser source={"code"} />
        <InputGuesser source={"date"} />
        <InputGuesser source={"dateValidite"} />
        <InputGuesser source={"nombreTentative"} />
        <SelectInput source="type" name={"type"} field={"type"} choices={types} />,
        <InputGuesser source={"data"} label={"Données"} />
    </EditGuesser>
);

