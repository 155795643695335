import { formatInTimeZone } from 'date-fns-tz';

// Fonction pour obtenir le décalage horaire actuel d'un fuseau horaire
const getTimezoneOffset = (timezone) => {
    const now = new Date();
    try {
        // Format la date dans le fuseau horaire donné avec le décalage UTC
        const formatted = formatInTimeZone(now, timezone, "zzz");
        return formatted;
    } catch (error) {
        console.error(`Erreur pour le fuseau horaire ${timezone}:`, error);
        return '';
    }
};

// Fonction pour créer un séparateur
const createSeparator = (label) => ({
    id: `separator-${label}`,
    name: `── ${label} ──`,
    isSeparator: true
});

// Liste des fuseaux horaires avec leurs noms
const rawTimezones = [
    createSeparator('France métropolitaine'),
    { id: "Europe/Paris", name: "Europe/Paris" },

    createSeparator('DOM-TOM français'),
    { id: "America/Martinique", name: "France - Martinique" },
    { id: "America/Guadeloupe", name: "France - Guadeloupe" },
    { id: "America/Cayenne", name: "France - Guyane" },
    { id: "Indian/Reunion", name: "France - La Réunion" },
    { id: "Indian/Mayotte", name: "France - Mayotte" },
    { id: "Pacific/Noumea", name: "France - Nouvelle-Calédonie" },
    { id: "Pacific/Tahiti", name: "France - Polynésie française (Tahiti)" },
    { id: "Pacific/Marquesas", name: "France - Polynésie française (Marquises)" },
    { id: "Pacific/Gambier", name: "France - Polynésie française (Gambier)" },
    { id: "Indian/Kerguelen", name: "France - TAAF Kerguelen" },
    { id: "Pacific/Wallis", name: "France - Wallis-et-Futuna" },
    { id: "America/Miquelon", name: "France - Saint-Pierre-et-Miquelon" },

    createSeparator('Amériques'),
    { id: "Pacific/Niue", name: "Pacific/Niue" },
    { id: "Pacific/Honolulu", name: "Pacific/Honolulu" },
    { id: "America/Anchorage", name: "America/Anchorage" },
    { id: "America/Los_Angeles", name: "America/Los Angeles" },
    { id: "America/Denver", name: "America/Denver" },
    { id: "America/Chicago", name: "America/Chicago" },
    { id: "America/New_York", name: "America/New York" },
    { id: "America/Santiago", name: "America/Santiago" },
    { id: "America/Sao_Paulo", name: "America/Sao Paulo" },
    { id: "America/Noronha", name: "America/Noronha" },
    { id: "Atlantic/Cape_Verde", name: "Atlantic/Cape Verde" },

    createSeparator('Europe'),
    { id: "UTC", name: "UTC" },
    { id: "Europe/London", name: "Europe/London" },
    { id: "Europe/Dublin", name: "Europe/Dublin" },
    { id: "Europe/Berlin", name: "Europe/Berlin" },
    { id: "Europe/Rome", name: "Europe/Rome" },
    { id: "Europe/Madrid", name: "Europe/Madrid" },
    { id: "Europe/Amsterdam", name: "Europe/Amsterdam" },
    { id: "Europe/Brussels", name: "Europe/Brussels" },
    { id: "Europe/Copenhagen", name: "Europe/Copenhagen" },
    { id: "Europe/Stockholm", name: "Europe/Stockholm" },
    { id: "Europe/Vienna", name: "Europe/Vienna" },
    { id: "Europe/Warsaw", name: "Europe/Warsaw" },
    { id: "Europe/Zurich", name: "Europe/Zurich" },
    { id: "Europe/Athens", name: "Europe/Athens" },
    { id: "Europe/Bucharest", name: "Europe/Bucharest" },
    { id: "Europe/Helsinki", name: "Europe/Helsinki" },
    { id: "Europe/Kiev", name: "Europe/Kiev" },
    { id: "Europe/Moscow", name: "Europe/Moscow" },

    createSeparator('Afrique et Moyen-Orient'),
    { id: "Africa/Casablanca", name: "Africa/Casablanca" },
    { id: "Africa/Cairo", name: "Africa/Cairo" },
    { id: "Europe/Istanbul", name: "Europe/Istanbul" },
    { id: "Asia/Dubai", name: "Asia/Dubai" },

    createSeparator('Asie'),
    { id: "Asia/Karachi", name: "Asia/Karachi" },
    { id: "Asia/Kolkata", name: "Asia/Kolkata" },
    { id: "Asia/Kathmandu", name: "Asia/Kathmandu" },
    { id: "Asia/Dhaka", name: "Asia/Dhaka" },
    { id: "Asia/Yangon", name: "Asia/Yangon" },
    { id: "Asia/Bangkok", name: "Asia/Bangkok" },
    { id: "Asia/Singapore", name: "Asia/Singapore" },
    { id: "Asia/Hong_Kong", name: "Asia/Hong Kong" },
    { id: "Asia/Seoul", name: "Asia/Seoul" },
    { id: "Asia/Tokyo", name: "Asia/Tokyo" },

    createSeparator('Océanie'),
    { id: "Australia/Perth", name: "Australia/Perth" },
    { id: "Australia/Darwin", name: "Australia/Darwin" },
    { id: "Australia/Brisbane", name: "Australia/Brisbane" },
    { id: "Australia/Adelaide", name: "Australia/Adelaide" },
    { id: "Australia/Melbourne", name: "Australia/Melbourne" },
    { id: "Australia/Sydney", name: "Australia/Sydney" },
    { id: "Pacific/Auckland", name: "Pacific/Auckland" },
    { id: "Pacific/Apia", name: "Pacific/Apia" },
    { id: "Pacific/Kiritimati", name: "Pacific/Kiritimati" }
];

// Ajoute le décalage horaire actuel à chaque fuseau horaire
export const timezones = rawTimezones.map(tz => {
    // Ne pas ajouter le décalage horaire aux séparateurs
    if (tz.isSeparator) return tz;
    
    return {
        ...tz,
        name: `${tz.name} (${getTimezoneOffset(tz.id)})`
    };
});
