import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    ImageField,
    ReferenceManyField,
    ImageInput,
    FormDataConsumer,
    useRefresh,
    Button,
} from 'react-admin';
import {useParams} from "react-router-dom";
import React from "react";
import {useMutation} from "react-query";

const EmploiListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const emploiFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <ReferenceInput source="auteur" reference="utilisateurs" name={"Auteur"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
            name={"auteur"}/>
    </ReferenceInput>,
    <TextInput source={"titre"} />,
    <TextInput source={"contenu"} />,
];

const rowClick = (id, resource, record) => {
    return 'show';
};

export const OffreEmploiList = props => {
    const { communauteId } = useParams();
    return (
        <List {...props}
              resource="offre_emplois"
              actions={<EmploiListActions />}
              filter={communauteId !== undefined ? { communaute: communauteId } : undefined}
              filters={emploiFilters}>
            <DatagridConfigurable rowClick={rowClick}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <FieldGuesser source={"titre"} />
                <FieldGuesser source={"contenu"} />
                <ReferenceField reference="utilisateurs" source="auteur" label="Auteur">
                    <TextField source="name" />
                </ReferenceField>
                <FieldGuesser source={"createdAt"} label={"Date de création"} />
            </DatagridConfigurable>
        </List>
    );
}

export const OffreEmploiShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"communaute"} />
        <FieldGuesser source={"auteur"} />
        <FieldGuesser source={"titre"} />
        <FieldGuesser source={"contenu"} />
        <FieldGuesser source={"adresse"} />
        <FieldGuesser source={"codePostal"} />
        <FieldGuesser source={"ville"} />
        <FieldGuesser source={"pays"} />
        <FieldGuesser source={"latitude"} />
        <FieldGuesser source={"longitude"} />
        <ImageField label={"Image de couverture"} source={"couverture"}/>
        <ReferenceManyField
            reference="offre_emploi_media"
            target="offre_emploi"
            // sort={{ field: 'created_at', order: 'DESC' }}
        >
            <Datagrid>
                <ImageField source="src" />
                {/*<EditButton />*/}
            </Datagrid>
        </ReferenceManyField>
    </ShowGuesser>
);

export const OffreEmploiEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <TextInput source={"titre"} fullWidth={true} />
        <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} />
        <InputGuesser source={"adresse"} />
        <InputGuesser source={"codePostal"} />
        <InputGuesser source={"ville"} />
        <InputGuesser source={"pays"} />
        <InputGuesser source={"latitude"} />
        <InputGuesser source={"longitude"} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" />
        </ImageInput>
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Changer d'image de couverture"
                    accept="image/*"
        >
            <ImageField source="src"/>
        </ImageInput>
        <ImageField source={"couverture"}/>
        <FormDataConsumer>
            {({
                  formData,
                  scopedFormData,
                  getSource,
                  ...rest
              }) => (formData.couverture !== null) ? <SupprimerCouvertureBouton/> : null}

        </FormDataConsumer>
    </EditGuesser>
);

export const OffreEmploiCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <TextInput source={"titre"} fullWidth={true} />
        <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} />
        <InputGuesser source={"adresse"} />
        <InputGuesser source={"codePostal"} />
        <InputGuesser source={"ville"} />
        <InputGuesser source={"pays"} />
        <InputGuesser source={"latitude"} />
        <InputGuesser source={"longitude"} />
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Ajouter une image de couverture"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            {/*<ImageField source="mediaUrl" title="filename" />*/}
            <ImageField source="src" />
        </ImageInput>
    </CreateGuesser>
);
const SupprimerCouvertureBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `offre_emplois`,
            {id: record.id, data: {deleteCouverture: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );

    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'image de couverture</Button>;
};