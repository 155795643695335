import React, {useState} from 'react';
import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout, ReferenceArrayInput, Link
} from 'react-admin';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete, StandaloneSearchBox } from '@react-google-maps/api';
import { useMutation } from 'react-query';
import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,
} from 'ra-input-rich-text';
import {Box, Button, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useWatch, useFormContext} from "react-hook-form";
import {CommunauteInput} from "../components/inputs";
import {useParams} from "react-router-dom";

const SignalementListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const filters = [
    <SearchInput source="q" alwaysOn />,
    <CommunauteInput source="communaute" />
];

export const SignalementList = props => {
    const { communauteId } = useParams();
    return (
        <List
            resource="signalements"
            filter={communauteId !== undefined ? { communaute: communauteId } : undefined}
            filters={filters}
            actions={<SignalementListActions />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            {...props}>
            <DatagridConfigurable rowClick={"edit"}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <ReferenceField reference={"signalement_categories"} source={"signalementCategorie"} label={"Catégories"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <FieldGuesser source={"etat"} />
                <FieldGuesser source={"adresse"} />
                <FieldGuesser source={"codePostal"} />
                <FieldGuesser source={"ville"} />
                <FieldGuesser source={"pays"} />
                <ReferenceField reference="utilisateurs" source="auteur" label="Auteur">
                    <TextField source="email" />
                </ReferenceField>
                <FieldGuesser source={"createdAt"} label="Date création" />
                {/*<FieldGuesser source={"medias"} />*/}
                <EditSignalementButton />
            </DatagridConfigurable>
        </List>
    );
}

const EditSignalementButton = () => {
    const signalement = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/communautes/${signalement?.communaute}/signalements/${signalement?.id}`}
            //startIcon={<EditIcon />}
        >
            Editer
        </Button>
    );
};

const CategorieInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceInput reference="signalement_categories" filter={{communaute: communaute}} {...props} />
            }
        </FormDataConsumer>
    )
};

const containerStyle = {
    width: '100%',
    height: '500px'
};

const position = {
    lat: 37.772,
    lng: -122.214
}

const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    right: '10px',
}

let searchBox = null;


const MyGoogleMap = ({ lat, lng }) => {
    const {getValues, setValue} = useFormContext();
    const [ libraries ] = useState(['places']);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDIVkSX6SuDf EwEmIaV-Ttga_VCgtYrtiw",
        libraries:  libraries,
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(position);
        map.fitBounds(bounds);

        setMap(map);

        if(getValues("latitude") !== undefined && getValues("longitude") !== undefined) {
            console.log(getValues("latitude"));
            console.log(getValues("longitude"));
            const center = {
                lat: parseFloat(getValues("latitude")),
                lng: parseFloat(getValues("longitude"))
            };
            const marker = new window.google.maps.Marker({
                position: center,
                map: map,
                draggable: true,
                title: 'Current location',
            });
            map.panTo(center);
            map.setZoom(16);
        }
    }, [])

    const onLoadMarker = marker => {
        console.log('marker: ', marker)
    }

    const onLoadSearchBox = ref => {
        searchBox = ref;
    }

    const onPlacesChanged = () => {
        console.log(searchBox.getPlaces());
        const places = searchBox.getPlaces();
        if (places.length > 0) {
            setValue('latitude', places[0].geometry.location.lat());
            setValue('longitude', places[0].geometry.location.lng());

            // Add marker to map
            const marker = new window.google.maps.Marker({
                position: places[0].geometry.location,
                map: map,
                draggable: true,
                title: 'Current location',
            })
            marker.addListener('click', () => {
                setValue('latitude', places[0].geometry.location.lat());
                setValue('longitude', places[0].geometry.location.lng());
            })

            // Recentrer la carte
            map.panTo(places[0].geometry.location);
            map.setZoom(16);

            places.forEach(place => {
                place.address_components.forEach(component => {
                    if (component.types.includes('locality')) {
                        setValue('ville', component.long_name);
                    }
                    if (component.types.includes('street_number')) {
                        setValue('adresse', component.long_name);
                    }
                    if (component.types.includes('route')) {
                        setValue('adresse', getValues('adresse') + ' ' + component.long_name);
                    }
                    if (component.types.includes('postal_code')) {
                        setValue('codePostal', component.long_name);
                    }
                    if (component.types.includes('country')) {
                        setValue('pays', component.long_name);
                    }
                })
            })
        }
    }

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={position}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {
                <StandaloneSearchBox
                    onLoad={onLoadSearchBox}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type='text'
                        placeholder='Customized your placeholder'
                        style={inputStyle}
                    />
                </StandaloneSearchBox>
            }
{/*            {

                <Marker
                    onLoad={onLoadMarker}
                    position={position}
                    draggable={true}
                    editable={true}
                    onDragEnd={(e) => {
                        console.log(e.latLng.lat(), e.latLng.lng());
                        setValue("latitude", e.latLng.lat());
                        setValue("longitude", e.latLng.lng());
                    }}
                />
            }*/}

        </GoogleMap>
    ) : <></>
}

export const SignalementCreate = props => (
    <CreateGuesser {...props}>
        <CommunauteInput source="communaute" />
        <CategorieInput source={"signalementCategorie"} />
        <Box sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)',
        }}>
            <InputGuesser source={"adresse"} />
            <InputGuesser source={"codePostal"} />
            <InputGuesser source={"ville"} />
            <InputGuesser source={"pays"} />
        </Box>
        <MyGoogleMap />
        <Box sx={{ justifyContent: 'flex-start' }}>
            <InputGuesser source={"latitude"} />
            <InputGuesser source={"longitude"} />
        </Box>
        <TextInput source={"commentaire"} multiline={true} rows={10} fullWidth={true} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            {/*<ImageField source="mediaUrl" title="filename" />*/}
            <ImageField source="src" />
        </ImageInput>
    </CreateGuesser>
);

export const SignalementEdit = props => {
    const { communauteId, id } = useParams();
    console.log(communauteId, id);
    return (
        <EditGuesser {...props}
                 redirect={communauteId !== undefined ? { to: `/communautes/${communauteId}/signalements` } : undefined}
        >
            <CommunauteInput source="communaute" />
            <CategorieInput source={"signalementCategorie"} />
            <Box sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: 'repeat(2, 1fr)',
            }}>
                <InputGuesser source={"adresse"} />
                <InputGuesser source={"codePostal"} />
                <InputGuesser source={"ville"} />
                <InputGuesser source={"pays"} />
            </Box>
            <MyGoogleMap />
            <Box sx={{ justifyContent: 'flex-start' }}>
                <InputGuesser source={"latitude"} />
                <InputGuesser source={"longitude"} />
            </Box>
            <TextInput source={"commentaire"} multiline={true} rows={10} fullWidth={true} />
            <ImageInput multiple={true}
                        source="medias"
                        label="Related pictures"
                        accept="image/*">
                {/*<ImageField source="mediaUrl" title="filename" />*/}
                <ImageField source="src" />
            </ImageInput>
        </EditGuesser>
    );
}